// src/components/Navbar.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <nav>
            <Link to="/provincias">Provincias</Link>
            <Link to="/municipios">Municipios</Link>
            <Link to="/distritos">Distritos</Link>
            <Link to="/proprietarios">Proprietarios</Link>
        </nav>
    );
};

export default Navbar;