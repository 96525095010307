import React, { useEffect, useState } from "react";
import {
  fetchMunicipalities,
  createMunicipality,
  updateMunicipality,
  deleteMunicipality,
  fetchProvinces,
} from "../api";

const MunicipalityCRUD = () => {
  const [municipalities, setMunicipalities] = useState([]);
  const [name, setName] = useState("");
  const [provinceId, setProvinceId] = useState("");
  const [editingId, setEditingId] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    const loadMunicipalities = async () => {
      const response = await fetchMunicipalities();
      setMunicipalities(Array.isArray(response.data) ? response.data : []); // Ensure it's an array
    };
    loadMunicipalities();
  }, []);

  useEffect(() => {
    const loadProvinces = async () => {
      const response = await fetchProvinces();
      setProvinces(Array.isArray(response.data) ? response.data : []); // Ensure it's an array
    };
    loadProvinces();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const confirmMessage = editingId
      ? "Tem certeza de que deseja actualizar este municipio?"
      : "Tem certeza de que deseja adicionar este municipio?";

    if (window.confirm(confirmMessage)) {
      if (editingId) {
        await updateMunicipality(editingId, {
          nome: name,
          provincia_id: provinceId,
        });
      } else {
        await createMunicipality({ nome: name, provincia_id: provinceId });
      }
      setName("");
      setProvinceId("");
      setEditingId(null);
      const response = await fetchMunicipalities();
      setMunicipalities(Array.isArray(response.data) ? response.data : []); // Ensure it's an array
    }
  };

  const handleEdit = (municipality) => {
    setName(municipality.nome);
    setProvinceId(municipality.provincia_id);
    setEditingId(municipality.id);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Tem certeza de que deseja apagar este municipio?")) {
      await deleteMunicipality(id);
      const response = await fetchMunicipalities();
      setMunicipalities(Array.isArray(response.data) ? response.data : []); // Ensure it's an array
    }
  };

  // Filter and sort municipalities based on search term and sort order
  const filteredMunicipalities = Array.isArray(municipalities) ? municipalities
    .filter((municipality) =>
      municipality.nome.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      return sortOrder === "asc"
        ? a.nome.localeCompare(b.nome)
        : b.nome.localeCompare(a.nome);
    }) : [];

  // Calculate the current items to display
  const indexOfLastMunicipality = currentPage * itemsPerPage;
  const indexOfFirstMunicipality = indexOfLastMunicipality - itemsPerPage;
  const currentMunicipalities = filteredMunicipalities.slice(
    indexOfFirstMunicipality,
    indexOfLastMunicipality
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(filteredMunicipalities.length / itemsPerPage);

  return (
    <div>
      <h2>Municipios</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nome do municipio"
          required
        />
        <select
          value={provinceId}
          onChange={(e) => setProvinceId(e.target.value)}
          required
        >
          <option value="">Select Province</option>
          {provinces.map((province) => (
            <option key={province.id} value={province.id}>
              {province.nome}
            </option>
          ))}
        </select>
        <button type="submit">
          {editingId ? "Actualizar" : "Adicionar"} Municipio
        </button>
      </form>

      <div>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Buscar municipio"
        />
        <select onChange={(e) => setSortOrder(e.target.value)} value={sortOrder}>
          <option value="asc">Ordenar por Nome (Ascendente)</option>
          <option value="desc">Ordenar por Nome (Descendente)</option>
        </select>
        <select onChange={(e) => setItemsPerPage(Number(e.target.value))} value={itemsPerPage}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </select>
      </div>

      <ul>
        {currentMunicipalities.map((municipality) => (
          <li key={municipality.id}>
            {municipality.nome} - ({municipality.provincia_name})
            <button onClick={() => handleEdit(municipality)}>Editar</button>
            <button onClick={() => handleDelete(municipality.id)}>Apagar</button>
          </li>
        ))}
      </ul>

      <div>
        <p>Página {currentPage} de {totalPages}</p>
        <div>
          {Array.from({ length: totalPages }, (_, index) => (
            <button key={index + 1} onClick={() => paginate(index + 1)}>
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MunicipalityCRUD;
