// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import ProvinceCRUD from "./components/ProvinceCRUD";
import MunicipalityCRUD from "./components/MunicipalityCRUD";
import DistrictCRUD from "./components/DistrictCRUD";
import Proprietario from "./components/Proprietario";


const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/provincias" element={<ProvinceCRUD />} />
        <Route path="/municipios" element={<MunicipalityCRUD />} />
        <Route path="/distritos" element={<DistrictCRUD />} />
        <Route path="/proprietarios" element={<Proprietario />} />
      </Routes>
    </Router>
  );
};

export default App;
