// src/App.js
import React, { useState } from 'react';
import ProprietarioList from './ProprietarioList';
import ProprietarioForm from './ProprietarioForm';


const Proprietario = () => {
  const [selectedProprietario, setSelectedProprietario] = useState(null);

  return (
    <div className="App">
      <h1>Proprietario CRUD</h1>
      <ProprietarioForm selectedProprietario={selectedProprietario} setSelectedProprietario={setSelectedProprietario} />
      <ProprietarioList setSelectedProprietario={setSelectedProprietario} />
    </div>
  );
}

export default Proprietario;
