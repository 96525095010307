// src/components/ProprietarioForm.js
import React, { useState, useEffect } from 'react';
import { createProprietario, updateProprietario } from '../api';

const ProprietarioForm = ({ selectedProprietario, setSelectedProprietario }) => {
  const [nome, setNome] = useState('');
  const [apelido, setApelido] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [genero, setGenero] = useState('');
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (selectedProprietario) {
      setNome(selectedProprietario.nome);
      setApelido(selectedProprietario.apelido);
      setTelefone(selectedProprietario.telefone);
      setEmail(selectedProprietario.email);
      setGenero(selectedProprietario.genero);
    }
  }, [selectedProprietario]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const proprietario = { nome, apelido, telefone, email, genero };

    if (selectedProprietario) {
      updateProprietario(selectedProprietario.id, proprietario)
        .then(() => {
          setSelectedProprietario(null); // Reset after submit
        })
        .catch(error => console.error('Error updating proprietario', error));
    } else {
      createProprietario(proprietario, file)
        .then(() => {
          // Reset form after submit
          setNome('');
          setApelido('');
          setTelefone('');
          setEmail('');
          setGenero('');
          setFile(null);
        })
        .catch(error => console.error('Error creating proprietario', error));
    }
  };

  return (
    <div>
      <h2>{selectedProprietario ? 'Edit Proprietario' : 'Create Proprietario'}</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Nome:</label>
          <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} required />
        </div>
        <div>
          <label>Apelido:</label>
          <input type="text" value={apelido} onChange={(e) => setApelido(e.target.value)} required />
        </div>
        <div>
          <label>Telefone:</label>
          <input type="text" value={telefone} onChange={(e) => setTelefone(e.target.value)} required />
        </div>
        <div>
          <label>Email:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div>
          <label>Genero:</label>
          <select value={genero} onChange={(e) => setGenero(e.target.value)} required>
            <option value="M">Masculino</option>
            <option value="F">Feminino</option>
          </select>
        </div>
        <div>
          <label>File:</label>
          <input type="file" onChange={(e) => setFile(e.target.files[0])} />
        </div>
        <button type="submit">{selectedProprietario ? 'Update' : 'Create'}</button>
      </form>
    </div>
  );
};

export default ProprietarioForm;
