// src/components/ProprietarioList.js
import React, { useEffect, useState } from 'react';
import { getAllProprietarios, deleteProprietario } from '../api';

const ProprietarioList = ({ setSelectedProprietario }) => {
  const [proprietarios, setProprietarios] = useState([]);

  useEffect(() => {
    // Fetch all proprietarios from the API
    getAllProprietarios()
      .then(response => {
        // Ensure the response is an array
        const data = response.data;
        if (Array.isArray(data)) {
          setProprietarios(data);
        } else {
          console.error('Invalid response format: expected an array of proprietarios');
          setProprietarios([]);  // Ensure state is always an array
        }
      })
      .catch(error => {
        console.error('Error fetching proprietarios', error);
        setProprietarios([]);  // In case of error, set an empty array
      });
  }, []);

  const handleDelete = (id) => {
    deleteProprietario(id)
      .then(() => {
        // Remove deleted item from the state
        setProprietarios(proprietarios.filter(p => p.id !== id));
      })
      .catch(error => console.error('Error deleting proprietario', error));
  };

  return (
    <div>
      <h2>Proprietarios</h2>
      <ul>
        {/* Check if proprietarios is an array before calling map */}
        {Array.isArray(proprietarios) && proprietarios.length > 0 ? (
          proprietarios.map((proprietario) => (
            <li key={proprietario.id}>
              {proprietario.nome} {proprietario.apelido}
              <button onClick={() => setSelectedProprietario(proprietario)}>Edit</button>
              <button onClick={() => handleDelete(proprietario.id)}>Delete</button>
            </li>
          ))
        ) : (
          <li>No proprietarios found</li>
        )}
      </ul>
    </div>
  );
};

export default ProprietarioList;
