import React, { useEffect, useState } from 'react';
import { fetchProvinces, createProvince, updateProvince, deleteProvince } from '../api';

const ProvinceCRUD = () => {
    const [provinces, setProvinces] = useState([]);
    const [name, setName] = useState('');
    const [editingId, setEditingId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Initial items per page
    const [searchTerm, setSearchTerm] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');

    useEffect(() => {
        const loadProvinces = async () => {
            const response = await fetchProvinces();
            // Ensure response data is an array
            setProvinces(Array.isArray(response.data) ? response.data : []);
        };
        loadProvinces();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const confirmMessage = editingId
            ? "Tem certeza de que deseja actualizar esta provincia?"
            : "Tem certeza de que deseja adicionar esta provincia?";

        if (window.confirm(confirmMessage)) {
            if (editingId) {
                await updateProvince(editingId, { nome: name });
            } else {
                await createProvince({ nome: name });
            }
            setName('');
            setEditingId(null);
            const response = await fetchProvinces();
            setProvinces(Array.isArray(response.data) ? response.data : []);
        }
    };

    const handleEdit = (province) => {
        setName(province.nome);
        setEditingId(province.id);
    };

    const handleDelete = async (id) => {
        if (window.confirm("Tem certeza de que deseja apagar esta provincia?")) {
            await deleteProvince(id);
            const response = await fetchProvinces();
            setProvinces(Array.isArray(response.data) ? response.data : []);
        }
    };

    const filteredProvinces = Array.isArray(provinces) ? provinces.filter(province =>
        province.nome.toLowerCase().includes(searchTerm.toLowerCase())
    ) : [];

    const sortedProvinces = filteredProvinces.sort((a, b) => {
        return sortOrder === 'asc'
            ? a.nome.localeCompare(b.nome)
            : b.nome.localeCompare(a.nome);
    });

    const indexOfLastProvince = currentPage * itemsPerPage;
    const indexOfFirstProvince = indexOfLastProvince - itemsPerPage;
    const currentProvinces = sortedProvinces.slice(indexOfFirstProvince, indexOfLastProvince);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const totalPages = Math.ceil(filteredProvinces.length / itemsPerPage);

    return (
        <div>
            <h2>Provincias</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nome da provincia"
                    required
                />
                <button type="submit">{editingId ? 'Actualizar' : 'Adicionar'} Provincia</button>
            </form>

            <div>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Buscar provincia"
                />
                <select onChange={(e) => setSortOrder(e.target.value)} value={sortOrder}>
                    <option value="asc">Ordenar por Nome (Ascendente)</option>
                    <option value="desc">Ordenar por Nome (Descendente)</option>
                </select>
                <select onChange={(e) => setItemsPerPage(Number(e.target.value))} value={itemsPerPage}>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                </select>
            </div>

            <ul>
                {currentProvinces.map((province) => (
                    <li key={province.id}>
                        {province.nome}
                        <button onClick={() => handleEdit(province)}>Editar</button>
                        <button onClick={() => handleDelete(province.id)}>Apagar</button>
                    </li>
                ))}
            </ul>
            <div>
                <p>Página {currentPage} de {totalPages}</p>
                <div>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button key={index + 1} onClick={() => paginate(index + 1)}>
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProvinceCRUD;
