import React, { useEffect, useState } from 'react';
import { fetchDistricts, createDistrict, updateDistrict, deleteDistrict, fetchProvinces, fetchMunicipalities } from '../api';

const DistrictCRUD = () => {
  const [districts, setDistricts] = useState([]);
  const [name, setName] = useState('');
  const [municipalityId, setMunicipalityId] = useState('');
  const [provinceId, setProvinceId] = useState('');
  const [editingId, setEditingId] = useState(null);
  const [municipalities, setMunicipalities] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [districtsPerPage, setDistrictsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState('asc');

  // Fetch provinces and districts on initial render
  useEffect(() => {
    const loadDistricts = async () => {
      const response = await fetchDistricts();
      setDistricts(Array.isArray(response.data) ? response.data : []);
    };

    const loadProvinces = async () => {
      const response = await fetchProvinces();
      setProvinces(Array.isArray(response.data) ? response.data : []);
    };

    loadDistricts();
    loadProvinces();
  }, []);

  // Fetch municipalities when provinceId changes
  useEffect(() => {
    const loadMunicipalities = async () => {
      if (provinceId) {
        const response = await fetchMunicipalities(provinceId);
        setMunicipalities(Array.isArray(response.data) ? response.data : []);
        setMunicipalityId(''); // Reset municipality when province changes
      } else {
        setMunicipalities([]);
        setMunicipalityId('');
      }
    };
    loadMunicipalities();
  }, [provinceId]);

  // Handle form submission (create or update district)
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (editingId) {
      if (window.confirm('Are you sure you want to update this district?')) {
        await updateDistrict(editingId, { nome: name, municipio_id: municipalityId });
      }
    } else {
      await createDistrict({ nome: name, municipio_id: municipalityId });
    }

    resetForm();
    await refreshDistricts();
  };

  // Reset form and states
  const resetForm = () => {
    setName('');
    setMunicipalityId('');
    setProvinceId('');
    setEditingId(null);
    setMunicipalities([]);
  };

  // Refresh districts list after a change
  const refreshDistricts = async () => {
    const response = await fetchDistricts();
    setDistricts(Array.isArray(response.data) ? response.data : []);
  };

  // Handle district edit
  const handleEdit = (district) => {
    setName(district.nome);
    setMunicipalityId(district.municipio_id);
    setProvinceId(district.province_id);
    setEditingId(district.id);
  };

  // Handle district delete
  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this district?')) {
      await deleteDistrict(id);
      await refreshDistricts();
    }
  };

  // Filter districts based on search term
  const filteredDistricts = Array.isArray(districts)
    ? districts.filter((district) => district.nome.toLowerCase().includes(searchTerm.toLowerCase()))
    : [];

  // Sort districts by name
  const sortedDistricts = filteredDistricts.sort((a, b) => {
    return sortOrder === 'asc'
      ? a.nome.localeCompare(b.nome)
      : b.nome.localeCompare(a.nome);
  });

  // Pagination logic
  const indexOfLastDistrict = currentPage * districtsPerPage;
  const indexOfFirstDistrict = indexOfLastDistrict - districtsPerPage;
  const currentDistricts = sortedDistricts.slice(indexOfFirstDistrict, indexOfLastDistrict);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(filteredDistricts.length / districtsPerPage);

  return (
    <div>
      <h2>Distritos</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nome do distrito"
          required
        />

        {/* Province Select */}
        <select value={provinceId} onChange={(e) => setProvinceId(e.target.value)} required>
          <option value="">Select Province</option>
          {provinces.map((province) => (
            <option key={province.id} value={province.id}>
              {province.nome}
            </option>
          ))}
        </select>

        {/* Municipality Select (Cascading from Province) */}
        <select
          value={municipalityId}
          onChange={(e) => setMunicipalityId(e.target.value)}
          required
          disabled={!provinceId} // Disable until a province is selected
        >
          <option value="">Select Municipality</option>
          {municipalities.map((municipality) => (
            <option key={municipality.id} value={municipality.id}>
              {municipality.nome}
            </option>
          ))}
        </select>

        <button type="submit">{editingId ? 'Atualizar' : 'Adicionar'} Distrito</button>
      </form>

      {/* Search and Sort */}
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Pesquisar por distritos"
      />
      <button onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}>
        Sort by Name {sortOrder === 'asc' ? '↑' : '↓'}
      </button>

      {/* Rows per page */}
      <div>
        <label>
          Rows per page:
          <select value={districtsPerPage} onChange={(e) => setDistrictsPerPage(Number(e.target.value))}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>
        </label>
      </div>

      {/* Districts List */}
      <ul>
        {currentDistricts.map((district) => (
          <li key={district.id}>
            {district.nome} (Municipality: {district.municipio_name})
            <button onClick={() => handleEdit(district)}>Edit</button>
            <button onClick={() => handleDelete(district.id)}>Delete</button>
          </li>
        ))}
      </ul>

      {/* Pagination */}
      <div>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            disabled={currentPage === index + 1}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DistrictCRUD;
