import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchProvinces = () => axios.get(`${API_URL}/provincias`);
export const createProvince = (data) => axios.post(`${API_URL}/provincias`, data);
export const updateProvince = (id, data) => axios.put(`${API_URL}/provincias/${id}`, data);
export const deleteProvince = (id) => axios.delete(`${API_URL}/provincias/${id}`);

export const fetchMunicipalities = () => axios.get(`${API_URL}/municipios`);
export const createMunicipality = (data) => axios.post(`${API_URL}/municipios`, data);
export const updateMunicipality = (id, data) => axios.put(`${API_URL}/municipios/${id}`, data);
export const deleteMunicipality = (id) => axios.delete(`${API_URL}/municipios/${id}`);

export const fetchDistricts = () => axios.get(`${API_URL}/distritos`);
export const createDistrict = (data) => axios.post(`${API_URL}/distritos`, data);
export const updateDistrict = (id, data) => axios.put(`${API_URL}/distritos/${id}`, data);
export const deleteDistrict = (id) => axios.delete(`${API_URL}/distritos/${id}`);

export const createProprietario = (proprietario, file) => {
    const formData = new FormData();
    formData.append('nome', proprietario.nome);
    formData.append('apelido', proprietario.apelido);
    formData.append('telefone', proprietario.telefone);
    formData.append('email', proprietario.email);
    formData.append('genero', proprietario.genero);
    formData.append('urlID', file);  
    return axios.post(`${API_URL}/proprietarios`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};
  
export const getAllProprietarios = () => axios.get(`${API_URL}/proprietarios`);
export const getProprietarioById = (id) => axios.get(`${API_URL}/proprietarios/${id}`);
export const updateProprietario = (id, updatedProprietario) => axios.put(`${API_URL}/proprietarios/${id}`, updatedProprietario);
export const deleteProprietario = (id) => axios.delete(`${API_URL}/proprietarios/${id}`);